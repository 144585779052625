<template>
  <div>
    <h4 class="pl-2 font-weight-bold">大会管理</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="fromDate"
                type="text"
                placeholder="開始期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="fromDate"
                  locale="ja"
                  @input="fromDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="toDate"
                type="text"
                placeholder="終了期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="toDate"
                  @input="toDateSelected"
                  button-only
                  locale="ja"
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <div class="d-flex">
              <b-button
                class="primary mb-4 mb-md-3 mr-2"
                @click="handleFilterEvents"
                block
                >検索</b-button
              >
              <b-button
                class="primary mb-4 mb-md-3 ml-2"
                block
                @click="infoModal()"
                >新規作成 <i class="fa fa-plus" aria-hidden="true"></i
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="col.key !== 'id'"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>

      <template #cell(title)="row">
        {{ row.item.title }}
      </template>

      <template #cell(onlineFlagText)="row">
        <b-badge v-if="row.item.onlineFlag" variant="success">
          {{ row.item.onlineFlagText }}
        </b-badge>
        <b-badge v-else>{{ row.item.onlineFlagText }}</b-badge>
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="infoModal(row.item)"
          ><b-badge variant="primary" class="py-2">更新</b-badge
          ><esports-loading-button
            v-if="row.item.eventId === eventInfo.eventId"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></span>
        <span
          role="button"
          class="mx-2 my-1 d-inline-block"
          @click="deleteModal(row.item)"
          ><b-badge variant="danger" class="py-2">削除</b-badge></span
        >
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetail(row, 'detail')"
          ><b-badge variant="info" class="text-white py-2">{{
            row.detailsShowing && row.item.isDetailRow
              ? "詳細非表示"
              : "詳細表示"
          }}</b-badge>
          <esports-loading-button
            v-if="row.item.isLoading"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetailHistoryEvent(row, 'history')"
          ><b-badge variant="warning" class="text-white py-2">応援履歴</b-badge>
          <esports-loading-button
            v-if="row.item.isHistoryLoading"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
      </template>

      <template #row-details="row">
        <b-card
          no-body
          class="overflow-hidden animate__animated"
          :class="[row.detailsShowing ? 'animate__zoomOut' : 'animate__zoomIn']"
        >
          <b-row no-gutters>
            <b-col md="3" lg="4">
              <b-card-img-lazy
                :src="row.item.picture"
                alt="Image"
                class="rounded-0"
                v-bind="mainProps('#fff')"
              ></b-card-img-lazy>
              <label class="mt-1 ml-1" v-if="row.item.content">{{
                row.item.content.labelFileName
              }}</label>
            </b-col>
            <b-col md="9" lg="8">
              <b-card-body>
                <h5>
                  {{ row.item.title }}
                </h5>
                <b-card-text class="mb-0">
                  <b-badge v-if="row.item.onlineFlag" variant="success">
                    {{ row.item.onlineFlagText }}
                  </b-badge>
                  <b-badge v-else>{{ row.item.onlineFlagText }}</b-badge>
                </b-card-text>
                <!-- <b-card-text class="mb-0"
                  ><b>投票数: </b>{{ row.item.vote }}
                </b-card-text> -->
                <b-card-text v-if="row.item.content">
                  <b>概要: </b>{{ row.item.content.description }}
                </b-card-text>
              </b-card-body>
              <ul>
                <!-- <li><b>Event ID</b>: {{ row.item.eventId }}</li> -->
                <template
                  v-if="row.item.content && row.item.type_action === 'detail'"
                >
                  <li>
                    <b>{{
                      row.item.content.gamerType === 1 ? "チーム戦" : "個人戦"
                    }}</b
                    >:
                    <b-badge
                      v-for="gamer in row.item.content.gamers"
                      :key="gamer.gamerId"
                      class="mr-2 text-black"
                      variant="primary"
                      >{{ gamer.name }}</b-badge
                    >
                  </li>
                  <li>
                    <b>賞金: </b>
                    <b-badge variant="warning">
                      {{ formatPoint(row.item.content.prize) }}円
                    </b-badge>
                  </li>
                  <li><b>開催地</b>: {{ row.item.venue }}</li>
                  <li><b>作成日</b>: {{ row.item.createdDate }}</li>
                  <li><b>開始日</b>: {{ row.item.startDate }}</li>
                  <li v-if="row.item.content">
                    <b>終了日</b>: {{ row.item.content.endDate }}
                  </li>
                  <li>
                    <b>応援期間</b>: {{ row.item.content.startVote }} &#8226;
                    {{ row.item.content.endVote }}
                  </li>
                  <li>
                    <b>優勝者: </b>
                    <template v-if="row.item.content.winner">
                      <b-avatar
                        :src="imageUserUrl(row.item.content.winnerInfo)"
                        class="mr-2 my-2"
                      >
                        <template #badge
                          ><b-icon
                            class="text-warning"
                            icon="star-fill"
                          ></b-icon
                        ></template> </b-avatar
                      >{{ row.item.content.winnerInfo.name }}
                    </template>
                  </li>
                  <li v-if="row.item.content.extensionInfos">
                    <b>追加項目:</b>
                    <template>
                      <p
                        class="mb-0"
                        v-for="(info, index) in Object.entries(
                          row.item.content.extensionInfos
                        )"
                        :key="index"
                      >
                        <b>{{ info[0] }}</b
                        >: {{ info[1] }}
                      </p>
                    </template>
                  </li>
                  <li v-if="row.item.content.linkInfos">
                    <b>配信:</b>
                    <template>
                      <p
                        class="mb-0"
                        v-for="(info, index) in Object.entries(
                          row.item.content.linkInfos
                        )"
                        :key="index"
                      >
                        <b>{{ info[0] }}</b
                        >: {{ info[1] }}
                      </p>
                    </template>
                  </li>
                  <li class="mb-2" v-if="row.item.content.urlInfos">
                    <b>URL:</b>
                    <template>
                      <p
                        class="mb-0"
                        v-for="(info, index) in Object.entries(
                          row.item.content.urlInfos
                        )"
                        :key="index"
                      >
                        <b>{{ info[0] }}</b
                        >: {{ info[1] }}
                      </p>
                    </template>
                  </li>
                   <li v-if="row.item.content.isVoteOther">
                    <b>その他を追加する</b>
                  </li>
                </template>
                <template v-else>
                  <li class="list-unstyled">
                    <b
                      v-if="
                        row.item.content.table && !row.item.content.table.length
                      "
                      >該当データが存在しません。</b
                    >
                    <b-table class="history-vote-table" striped hover :items="row.item.content.table" :fields="fieldsVote">
                      <template #cell(totalTickets）)="row">
                        <span
                          >{{ row.item.totalTickets
                          }}<span class="label-ticket">票</span></span
                        >
                      </template>
                      <template #cell(id)="row">
                        <button
                          class="mx-2 my-1 d-inline-block border-0 px-0"
                          :disabled="row.item.isAddPoint"
                          @click="confirmGivePoint(row.item)"
                          ><b-badge :variant="row.item.isAddPoint ? 'default': 'primary'" class="py-2">ドネト付与</b-badge>
                        </button>
                      </template>
                    </b-table>
                  </li>
                </template>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult"
    >
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0 ml-3"
          ></b-pagination>
        </template>
      </template>
    </esports-paging>

    <!-- create/update Game Modal -->
    <b-modal
      :id="eventInfo.id"
      :title="eventInfo.title_modal"
      footer-class="footer-event"
      @hide="reseteventInfo"
      @shown="handleUpdateEvent"
      content-class="shadow"
      ref="event-info-modal"
    >
      <div class="form-modal">
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
        <b-form-group class="mb-2">
          <b-form-input
            v-model="eventInfo.title"
            type="text"
            placeholder="大会タイトル"
            :state="ckStateGame('title')"
          ></b-form-input>
          <b-form-invalid-feedback
            >大会タイトルは必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <b-form-textarea
            placeholder="概要"
            v-model="eventInfo.description"
            rows="3"
            no-resize
          ></b-form-textarea>
          <b-form-invalid-feedback>概要は必須です。</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mb-2 filter-search mx-0">
          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
            class="dropdown-search"
            :class="{ 'is-invalid': $v.eventInfo.gameId.$error }"
          >
            <template #button-content>
              <b-form-input
                v-model="selected_name"
                type="text"
                readonly
                class="text-truncate selected-name"
                placeholder="ゲームタイトル"
              ></b-form-input>
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group class="mb-0" label-size="sm">
                <b-form-input
                  v-model="search_game"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <li class="dropdown-events">
              <ul class="list-group list-unstyled">
                <b-dropdown-item-button
                  v-for="(option, index) in availableOptions(
                    options_game,
                    search_game
                  )"
                  :key="index"
                  @click="onOptionGame({ option })"
                  class="text-truncate"
                >
                  <span
                    :title="option.text"
                    class="text-truncate d-block selected-name"
                    >{{ option.text }}</span
                  >
                </b-dropdown-item-button>
              </ul>
            </li>
            <b-dropdown-text
              v-if="availableOptions(options_game, search_game).length === 0"
            >
              該当ゲームタイトルがありません。
            </b-dropdown-text>
          </b-dropdown>
          <b-form-invalid-feedback
            >ゲームタイトルは必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <div class="d-flex">
            <span class="mr-2 pt-1" :class="{ 'text-primary': !selectedGamer }"
              >個人戦</span
            >
            <b-form-checkbox
              v-model="selectedGamer"
              @change="resetTags"
              size="lg"
              name="check-button"
              class="radio-cus"
              switch
            />
            <span class="pt-1" :class="{ 'text-primary': selectedGamer }"
              >チーム戦</span
            >
          </div>
        </b-form-group>
        <b-form-group class="mb-2">
          <label>{{ !selectedGamer ? "個人戦" : "チーム戦" }}</label>
          <b-form-select
            disabled
            :options="gameTypeList"
            v-model="gameType"
          ></b-form-select>
        </b-form-group>
        <b-form-group class="mb-2 filter-search mx-0">
          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
            class="dropdown-search"
          >
            <template #button-content>
              <!-- <b-form-tag
                @remove="removeTag(tag)"
                v-for="tag in selected_tags"
                :key="tag.value"
                variant="info"
                >{{ tag.text }}
              </b-form-tag> -->
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group class="mb-0" label-size="sm">
                <b-form-input
                  v-model="search_gamer"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <li class="dropdown-events">
              <ul class="list-group list-unstyled">
                <b-dropdown-item-button
                  v-for="(option, index) in availableOptions(
                    options_gamer,
                    search_gamer
                  )"
                  :key="index"
                  class="text-truncate"
                  @click="selectGamer({ option })"
                >
                  <span
                    :title="option.text"
                    class="text-truncate d-block selected-name"
                    >{{ option.text }}</span
                  >
                </b-dropdown-item-button>
              </ul>
            </li>
            <b-dropdown-text
              v-if="availableOptions(options_gamer, search_gamer).length === 0"
            >
              該当選手がありません。
            </b-dropdown-text>
          </b-dropdown>
          <b-form-invalid-feedback>{{
            !selectedGamer ? "選手は必須です。" : "チームは必須です。"
          }}</b-form-invalid-feedback>
        </b-form-group>
        <template v-if="selected_tags.length">
          <b-form-group class="mb-2 outer-wrapper">
            <Container
              @drop="onDrop"
              orientation="horizontal"
              behaviour="contain"
            >
              <Draggable v-for="tag in selected_tags" :key="tag.value">
                <b-form-tag @remove="removeTag(tag)" variant="info"
                  >{{ tag.text }}
                </b-form-tag>
              </Draggable>
            </Container>
          </b-form-group>
        </template>
        <div class="pseduo-track"></div>
        <b-form-group
          class="mb-2 filter-search mx-0"
          v-if="eventInfo.isUpdateWiner"
        >
          <label>優勝者</label>
          <b-dropdown
            size="sm"
            variant="outline-secondary"
            block
            menu-class="w-100"
            class="dropdown-search"
            :disabled="eventInfo.finished || eventInfo.other"
          >
            <template #button-content>
              <b-form-input
                v-model="selected_winner"
                type="text"
                readonly
                class="text-truncate selected-name"
                placeholder=""
              ></b-form-input>
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group class="mb-0" label-size="sm">
                <b-form-input
                  v-model="search_winner"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <li class="dropdown-events">
              <ul class="list-group list-unstyled">
                <b-dropdown-item-button
                  v-for="(option, index) in availableOptions(
                    options_winner,
                    search_winner
                  )"
                  :key="index"
                  @click="onOptionWinner({ option })"
                  class="text-truncate"
                >
                  <span
                    :title="option.text"
                    class="text-truncate d-block selected-name"
                    >{{ option.text }}</span
                  >
                </b-dropdown-item-button>
              </ul>
            </li>
            <b-dropdown-text
              v-if="availableOptions(options_gamer, search_winner).length === 0"
            >
              該当選手がありません。
            </b-dropdown-text>
          </b-dropdown>
        </b-form-group>
        <b-form-group class="mb-2" v-if="eventInfo.isUpdateWiner">
          <b-form-checkbox
            id="checkbox-other-name"
            v-model="eventInfo.other"
            :disabled="eventInfo.finished || isWinnerSelected"
            name="checkbox-other-name"
            @change="handleCheckOther"
            class="mb-2"
          >その他
          </b-form-checkbox>
          <b-form-input
            v-model="eventInfo.otherName"
            v-if="eventInfo.other"
            :disabled="eventInfo.finished"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mb-2">
          <label>賞金</label>
          <b-form-input
            v-model.number="eventInfo.prize"
            type="number"
            min="0"
            :state="ckStateGame('prize')"
          ></b-form-input>
          <b-form-invalid-feedback>賞金は必須です。</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mb-2">
          <label>キービジュアルで表示位置</label>
          <b-form-select
            v-model="eventInfo.selected_sort"
            :options="options_sort"
            class="mb-1"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group class="mb-2">
          <div class="d-flex">
            <span
              class="mr-2 pt-1"
              :class="{ 'text-default': !eventInfo.onlineFlag }"
              >オフライン</span
            >
            <b-form-checkbox
              v-model="eventInfo.onlineFlag"
              size="lg"
              name="check-button"
              :class="{ 'radio-cus-online': eventInfo.onlineFlag }"
              variant="success"
              switch
            />
            <span class="pt-1" :class="{ 'text-success': eventInfo.onlineFlag }"
              >オンライン</span
            >
          </div>
        </b-form-group>
        <b-form-group class="mb-2" v-if="!eventInfo.onlineFlag">
          <label>開催地</label>
          <b-form-input
            v-model="eventInfo.venue"
            type="text"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mb-2">
          <label
            >追加項目
            <span
              role="button"
              @click="addAttribute"
              v-if="eventInfo.extensionInfos.length < MAX_ATTRIBUTES"
              ><b-badge variant="success"
                >追加
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                ></i></b-badge></span
          ></label>
          <transition-group
            enter-active-class="animate__animated animate__slideInUp"
            leave-active-class="animate__animated animate__fadeOutDown"
          >
            <div
              class="d-flex align-items-center position-relative"
              v-for="(item, index) in eventInfo.extensionInfos"
              :key="`key-${index}`"
            >
              <b-row>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="項目名"
                      v-model="item.key"
                      :state="ckStateExtentInfo(index, 'key')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >項目名は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="値"
                      v-model="item.value"
                      :state="ckStateExtentInfo(index, 'value')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >値は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                class="pl-2 btn-remove-attribute"
                @click="removeAttribute(index)"
              >
                <i
                  class="fa fa-times text-danger"
                  aria-hidden="true"
                  role="button"
                ></i>
              </div>
            </div>
          </transition-group>
        </b-form-group>
        <b-form-group class="mb-2">
          <label
            >配信
            <span
              role="button"
              @click="addAttributeLink"
              v-if="eventInfo.linkInfos.length < MAX_ATTRIBUTES"
              ><b-badge variant="success"
                >追加
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                ></i></b-badge></span
          ></label>
          <transition-group
            enter-active-class="animate__animated animate__slideInUp"
            leave-active-class="animate__animated animate__fadeOutDown"
          >
            <div
              class="d-flex align-items-center position-relative"
              v-for="(item, index) in eventInfo.linkInfos"
              :key="`key-${index}`"
            >
              <b-row>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="項目名"
                      v-model="item.key"
                      :state="ckStateLinkInfos(index, 'key')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >項目名は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="値"
                      v-model="item.value"
                      :state="ckStateLinkInfos(index, 'value')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >値は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                class="pl-2 btn-remove-attribute"
                @click="removeAttributeLink(index)"
              >
                <i
                  class="fa fa-times text-danger"
                  aria-hidden="true"
                  role="button"
                ></i>
              </div>
            </div>
          </transition-group>
        </b-form-group>
        <b-form-group class="mb-2">
          <label
            >URL
            <span
              role="button"
              @click="addAttributeUrl"
              v-if="eventInfo.urlInfos.length < MAX_ATTRIBUTES"
              ><b-badge variant="success"
                >追加
                <i
                  class="fa fa-plus-circle"
                  aria-hidden="true"
                ></i></b-badge></span
          ></label>
          <transition-group
            enter-active-class="animate__animated animate__slideInUp"
            leave-active-class="animate__animated animate__fadeOutDown"
          >
            <div
              class="d-flex align-items-center position-relative"
              v-for="(item, index) in eventInfo.urlInfos"
              :key="`key-${index}`"
            >
              <b-row>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="項目名"
                      v-model="item.key"
                      :state="ckStateUrlInfos(index, 'key')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >項目名は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group class="mb-2">
                    <b-form-input
                      type="text"
                      placeholder="値"
                      v-model="item.value"
                      :state="ckStateUrlInfos(index, 'value')"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      >値は必須です。</b-form-invalid-feedback
                    >
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                class="pl-2 btn-remove-attribute"
                @click="removeAttributeUrl(index)"
              >
                <i
                  class="fa fa-times text-danger"
                  aria-hidden="true"
                  role="button"
                ></i>
              </div>
            </div>
          </transition-group>
        </b-form-group>
        <b-form-group class="mb-2">
          <label>日程</label>
          <div class="d-flex align-items-center mb-2 position-relative">
            <b-row class="w-100">
              <b-col class="pl-3 pr-4">
                <b-form-group class="mb-1">
                  <b-form-datepicker
                    v-model="eventInfo.startDate"
                    placeholder="開始日"
                    :hide-header="true"
                    label-help=""
                    :date-format-options="formatDateRange"
                    :state="ckStateGame('startDate')"
                    @input="startDateSelected"
                    locale="ja"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    >開始日は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col class="pl-2 pr-4">
                <b-form-group class="mb-1">
                  <b-form-datepicker
                    v-model="eventInfo.endDate"
                    placeholder="終了日"
                    :hide-header="true"
                    label-help=""
                    locale="ja"
                    :date-format-options="formatDateRange"
                    :state="ckStateGame('endDate')"
                    @input="endDateSelected"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    >終了日は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
        <b-form-group class="mb-2" v-if="eventInfo.startDate">
          <label>応援期間</label>
          <div class="d-flex align-items-center mb-2 position-relative">
            <b-row class="w-100">
              <b-col class="pl-3 pr-4">
                <b-form-group class="mb-1">
                  <b-form-datepicker
                    v-model="eventInfo.startVote"
                    :state="ckStateGame('startVote')"
                    placeholder="開始日"
                    :hide-header="true"
                    label-help=""
                    :date-format-options="formatDateRange"
                    @input="startVoteSelected"
                    locale="ja"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    >開始日は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col class="pl-2 pr-4">
                <b-form-group class="mb-1">
                  <b-form-datepicker
                    v-model="eventInfo.endVote"
                    placeholder="終了日"
                    :state="ckStateGame('endVote')"
                    :hide-header="true"
                    label-help=""
                    locale="ja"
                    :date-format-options="formatDateRange"
                    @input="endVoteSelected"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    >終了日は必須です。</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
        <b-form-group class="position-relative">
          <b-form-file
            v-model="eventInfo.file"
            placeholder=""
            :state="eventId ? '' : ckStateGame('file')"
            drop-placeholder=""
            class="hide-banner"
            accept="image/*"
          ></b-form-file>
          <label class="custom-file-label filename">{{ filename }}</label>
          <b-form-invalid-feedback
            >画像アップロードは必須です。</b-form-invalid-feedback
          >
        </b-form-group>
      </div>
      <template #modal-footer="{ close }">
        <b-form-checkbox
          id="checkbox-other"
          v-model="eventInfo.status"
          name="checkbox-other"
        >
          その他を追加する
        </b-form-checkbox>
        <b-button
          class="primary"
          type="submit"
          :disabled="isSubmitted || isDisableButtonWithOtherName"
          @click="onSubmitEvent(close)"
          >{{ eventId ? "更新" : "登録" }}
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
    <!-- delete Game Modal -->
    <b-modal id="deleteEvent" hide-header header-class="text-danger" centered>
      <div class="form-data">
        <h5 class="text-center py-3">
          このイベントを完全に削除してもよろしいでしょうか?
        </h5>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleDeleteEvent(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
    <!-- update Event Modal -->
    <b-modal
      @hide="resetConfirmModal"
      id="confirmUpdate"
      size="sm"
      hide-header
      centered
    >
      <p class="text-center">{{ msgBox }}</p>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleSubmitUpdate(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
    <!-- confirm Point Modal -->
    <b-modal id="confirmPoint" hide-header header-class="text-danger" centered>
      <div class="form-data">
        <h5 class="text-center py-3" v-if="voteUser">
          {{ voteUser.username || '' }} さんにドネトを付与します。よろしいですか。
        </h5>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleVoteWinner(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { Container, Draggable } from "vue-smooth-dnd";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import { applyDrag, generateItems } from "@/utils/helper";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

const greaterThanZero = (value) => value >= 0;

export default {
  name: "EventList",
  components: { Container, Draggable },
  data() {
    return {
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      eventId: "",
      items: [],
      fields: [
        {
          key: "title",
          label: "大会タイトル",
          sortable: true,
          sortDirection: "desc",
          class: "w-30",
        },
        //{ key: "vote", label: "投票数", sortable: true },
        {
          key: "startDate",
          label: "開始日",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "createdDate",
          label: "作成日",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "onlineFlagText",
          label: "開催地",
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: "id", label: "" },
      ],
      fieldsVote: [
        {
          key: "votedDate",
          label: "投票日",
        },
        {
          key: "username",
          label: "ユーザ名",
        },
        {
          key: "email",
          label: "メールアドレス",
        },
        {
          key: "gamerName",
          label: "チームor選手",
        },
        {
          key: "totalTickets",
          label: "応援数（合計）",
        },
        {
          key: 'twitterUsername',
          label: 'TwitterID'
        },
        {
          key: 'id',
          label: ''
        }
      ],
      filters: {
        title: "",
        vote: "",
        startDate: "",
        createdDate: "",
        onlineFlagText: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      filterOn: ["title", "createdDate", "startDate", "onlineFlagText"],
      eventInfo: {
        id: "info-modal",
        gameId: "",
        title_modal: "",
        isUpdateWiner: false,
        file: null,
        title: "",
        description: "",
        extensionInfos: [],
        linkInfos: [],
        urlInfos: [],
        gamerIds: [],
        startDate: null,
        endDate: null,
        startVote: null,
        endVote: null,
        onlineFlag: false,
        venue: "",
        winner: "",
        prize: "",
        selected_sort: 0,
        status: false,
        other: false,
        otherName: ''
      },
      eventTemp: null,
      options_sort: [
        { value: 0, text: "-" },
        { value: 1, text: "1-メイン" },
        { value: 2, text: "2-右側" },
      ],
      formatDateRange: {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      },
      MAX_ATTRIBUTES: 5,
      msgErrors: [],
      msgSuccess: null,
      selected_name: "",
      options_game: [],
      search_game: "",
      options_gamer: [],
      options_winner: [],
      options_team: [],
      options_player: [],
      selected_tags: [],
      search_gamer: "",
      gameTypeList: [
        { value: 0, text: "選手を選択してください。" },
        { value: 1, text: "チームを選択してください。" },
      ],
      gameType: 0,
      selectedGamer: false,
      msgBox: "",
      params: null,
      closeModalUpdate: null,
      selected_winner: "",
      search_winner: "",
      isWinnerSelected: false,
      isEnableCloseModal: false,
      isWinnerOtherSelected: false,
      filename: "画像をアップロード",
      limit: 0,
      isDetailRow: false,
      isVoteRow: false,
      type_action: "",
      items_ranking: generateItems(10, (i) => ({
        id: i,
        data: "Draggable " + i,
      })),
      voteUser: null
    };
  },
  mixins: [validationMixin, dismissCount],
  validations() {
    if (this.eventId) {
      return {
        eventInfo: {
          title: { required },
          gameId: { required },
          startDate: { required },
          endDate: { required },
          startVote: { required },
          endVote: { required },
          //gamerIds: { required },
          prize: { required, maxValue: greaterThanZero },
          extensionInfos: {
            $each: {
              key: { required },
              value: { required },
            },
          },
          linkInfos: {
            $each: {
              key: { required },
              value: { required },
            },
          },
          urlInfos: {
            $each: {
              key: { required },
              value: { required },
            },
          },
        },
      };
    }
    return {
      eventInfo: {
        title: { required },
        file: { required },
        gameId: { required },
        startDate: { required },
        endDate: { required },
        startVote: { required },
        endVote: { required },
        //gamerIds: { required },
        prize: { required, maxValue: greaterThanZero },
        extensionInfos: {
          $each: {
            key: { required },
            value: { required },
          },
        },
        linkInfos: {
          $each: {
            key: { required },
            value: { required },
          },
        },
        urlInfos: {
          $each: {
            key: { required },
            value: { required },
          },
        },
      },
    };
  },
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    selectedGamer(nVal) {
      this.selected_tags = this.selected_tags.length ? this.selected_tags : [];
      this.eventInfo.gamerIds = this.eventInfo.gamerIds.length
        ? this.eventInfo.gamerIds
        : [];
      if (nVal) {
        this.gameType = 1;
        this.options_gamer = this.options_team;
      } else {
        this.gameType = 0;
        this.options_gamer = this.options_player;
      }
    },
    filtered(nVal) {
      this.$nextTick(() => {
        this.currentPage =
          this.isDetailRow || this.isVoteRow ? this.currentPage : 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.totalRows = nVal.length;
      });
    },
    "eventInfo.file": {
      deep: true,
      immediate: true,
      handler(nVal) {
        if (nVal) {
          this.filename = nVal.name;
        } else {
          this.filename = "画像をアップロード";
        }
      },
    },
  },
  computed: {
    isValidGame() {
      return !this.$v.eventInfo.$anyError;
    },
    isResult() {
      return this.items.length;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });

      return filtered.length > 0 ? filtered : [];
    },
    isDisableButtonWithOtherName() {
      return this.eventInfo.other && !this.eventInfo.otherName;
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await Promise.all([
      this.getGameList(),
      this.getEventsAll(),
      this.getPlayerList(),
      this.getTeamsAll(),
    ]);
    this.hideBeforeLoading = true;
    this.eventTemp = { ...this.eventInfo };
    this.resetItemArraysEventTemp();

    if (this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    onDrop(dropResult) {
      this.selected_tags = applyDrag(this.selected_tags, dropResult);
    },
    async getGameList() {
      const result = await this.$store
        .dispatch("event/getGameList")
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.options_game = result.games.map((game) => {
          return {
            value: game.gameId,
            text: game.title,
          };
        });
      }
    },
    async getEventsAll(params) {
      const result = await this.$store
        .dispatch("adminEvent/getEventsAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.items = result.map((event, index) => {
          event.picture = `${process.env.VUE_APP_BACKEND + event.picture}`;

          if (event.createdDate) {
            event.createdDate = moment(event.createdDate).format("YYYY-MM-DD");
          }

          if (event.startDate) {
            event.startDate = moment(event.startDate).format("YYYY-MM-DD");
          }

          event.onlineFlagText = event.onlineFlag ? "オンライン" : "オフライン";

          if (event.venue) {
            event.onlineFlagText = event.venue;
          }

          event.isLoading = false;

          event.isHistoryLoading = false;

          return event;
        });

        this.items = orderBy(this.items, ["createdDate"], ["desc"]);

        this.totalRows = this.items.length;
        this.setLazyLoading(false);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getEventsAll(params);
    },
    async onSubmitEvent(close) {
      if (this.validate()) {
        let params = {
          file: this.eventInfo.file,
          eventInfo: "",
          eventId: this.eventId,
        };

        let extensionInfos = {};
        let linkInfos = {};
        let urlInfos = {};

        if (this.eventInfo.extensionInfos.length) {
          this.eventInfo.extensionInfos.forEach((item) => {
            extensionInfos[item.key] = item.value;
          });
        } else {
          extensionInfos = null;
        }

        if (this.eventInfo.linkInfos.length) {
          this.eventInfo.linkInfos.forEach((item) => {
            linkInfos[item.key] = item.value;
          });
        } else {
          linkInfos = null;
        }

        if (this.eventInfo.urlInfos.length) {
          this.eventInfo.urlInfos.forEach((item) => {
            urlInfos[item.key] = item.value;
          });
        } else {
          urlInfos = null;
        }

        let gamerIds = this.selected_tags.map((item) => item.value);

        let eventInfo = {
          title: this.eventInfo.title,
          gameId: this.eventInfo.gameId,
          description: this.eventInfo.description,
          topPosition: this.eventInfo.selected_sort,
          startDate: this.eventInfo.startDate,
          endDate: this.eventInfo.endDate,
          startVote: this.eventInfo.startVote,
          endVote: this.eventInfo.endVote,
          gamerType: this.gameType,
          gamerIds: gamerIds,
          onlineFlag: this.eventInfo.onlineFlag,
          prize: this.eventInfo.prize,
          extensionInfos,
          linkInfos,
          urlInfos,
          isVoteOther: this.eventInfo.status
        };

        if (this.eventId) {
          eventInfo.venue = this.eventInfo.venue;
          eventInfo.eventId = this.eventId;
          eventInfo.winner = this.eventInfo.winner;
          this.isEnableCloseModal = true;

          if (this.eventInfo.other) {
            eventInfo.winnerOther = this.eventInfo.otherName;
          }

        } else {
          this.showMsgConfirmCreate(() => {
            params.eventInfo = JSON.stringify(eventInfo);
            this.isEnableCloseModal = true;
            this.upsertEvent(params, close, true);
          });
          return;
        }

        params.eventInfo = JSON.stringify(eventInfo);

        this.showMsgConfirmUpdate(params, close);

        // this.eventId && this.isWinnerSelected
        //   ? this.showMsgConfirmUpdate(params, close)
        //   : this.upsertEvent(params, close, true);
      }
    },
    async upsertEvent(params, close, isLoading, cb) {
      this.isSubmitted = isLoading;

      const result = await this.$store
        .dispatch("adminEvent/upSertEvent", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }

          setTimeout(() => {
            this.scrollToTop("info-modal");
          }, 1000);
        });

      if (result) {
        this.reloadListEvent(result, close);

        if (cb) {
          cb();
        }
      }
    },
    async infoModal(item) {
      if (!item) {
        this.eventInfo.title_modal = `新規作成`;
        this.eventInfo.id = "info-modal";
        this.eventInfo.eventId = "";
        this.eventInfo.isUpdateWiner = false;

        this.eventTemp.title_modal = `新規作成`;
        this.eventTemp.id = "info-modal";
        this.eventTemp.eventId = "";
        this.eventTemp.isUpdateWiner = false;

        this.eventId = "";
        this.options_gamer = this.options_player;

        this.filename = "画像をアップロード";

        this.$root.$emit("bv::show::modal", this.eventInfo.id);
        return;
      }
      this.eventInfo.title_modal = `更新`;
      this.eventInfo.eventId = item.eventId;
      this.eventInfo.isUpdateWiner = true;

      const result = await this.$store
        .dispatch("adminEvent/getDetailEvent", item.eventId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.loadDataInfo(item, result);

        this.$root.$emit("bv::show::modal", this.eventInfo.id);
      }
    },
    async handleDeleteEvent(close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminEvent/deleteEvent", this.eventId)
        .catch((err) => {
          this.isSubmitted = false;
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.reloadListEvent(result, close);
      }
    },
    async handleVoteWinner(close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminEvent/voteWinnerByAdmin", {
          eventId: this.eventId,
          userId: this.voteUser.userId
        })
        .catch((err) => {
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
          this.isSubmitted = false;
          this.makeToast("danger", this.msgErrors[0]);
        });

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];
        this.voteUser.isAddPoint = true;
        this.isSubmitted = false;
        this.handleSuccessResp(close);
      }
    },
    async handleSubmitUpdate(closeConfirm) {
      await this.upsertEvent(this.params, this.closeModalUpdate, true);
      closeConfirm();
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.title = "";
      this.filters.vote = "";
      this.filters.startDate = "";
      this.filters.createdDate = "";
      this.filters.onlineFlagText = "";

      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    async reloadListEvent(result, close) {
      this.msgSuccess = result.message;
      this.msgErrors = [];
      this.isSubmitted = false;
      this.resetData();
      this.handleSuccessResp(close);
      await this.handleFilterEvents();
    },
    async getPlayerList() {
      const result = await this.$store
        .dispatch("adminPlayer/getPlayersAll")
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        let playerList = result.filter((player) => player.hidden === false);
        this.options_player = this.options_gamer = playerList.map((player) => {
          return {
            value: player.playerId,
            text: player.name,
          };
        });
      }
    },
    async getTeamsAll() {
      const result = await this.$store
        .dispatch("adminTeam/getTeamsAll")
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.options_team = result.map((team) => {
          return {
            value: team.teamId,
            text: team.title,
          };
        });
      }
    },
    renderTags(tags) {
      tags.forEach((item) => {
        let tagIndex = this.options_gamer.findIndex(
          (tag) => tag.value === item
        );
        if (tagIndex > -1) {
          this.selected_tags.push({
            text: this.options_gamer[tagIndex].text,
            value: this.options_gamer[tagIndex].value,
          });
        }
      });
    },
    showMsgConfirmUpdate(params, closeModalUpdate) {
      this.msgBox = this.isWinnerSelected || this.isWinnerOtherSelected
        ? "優勝ゲーマーを設定したら、変更することができません。本当によろしいでしょうか?"
        : "この大会を更新してもよろしいですか？";

      this.params = params;
      this.closeModalUpdate = closeModalUpdate;
      this.$root.$emit("bv::show::modal", "confirmUpdate");
    },
    showModalConfirmCloseEvent() {
      let msg = this.eventId
        ? "本当にキャンセルしてもよろしいでしょうか？"
        : "本当にキャンセルしてもよろしいでしょうか？";
      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2 footer-mgs",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isEnableCloseModal = true;
            this.$refs["event-info-modal"].hide();
          }
        });
    },
    showMsgConfirmCreate(cb) {
      this.$bvModal
        .msgBoxConfirm("このイベントを登録してもよろしいですか？", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.isEnableCloseModal = true;
          this.$refs["event-info-modal"].hide();
        });
    },
    removeTag(tag) {
      let tagIndex = this.selected_tags.findIndex(
        (item) => item.value === tag.value
      );
      if (tagIndex > -1) {
        this.selected_tags.splice(tagIndex, 1);
        this.eventInfo.gamerIds.splice(tagIndex, 1);
      }
    },
    selectGamer({ option }) {
      this.search_gamer = "";
      this.selected_tags.push(option);
      this.eventInfo.gamerIds.push(option.value);

      this.selected_tags = this.getUniqueListBy(this.selected_tags, "value");
      this.eventInfo.gamerIds = this.getUniqueListBy(
        this.eventInfo.gamerIds,
        ""
      );
    },
    fromDateSelected(date) {
      if (!this.toDate || moment(date).isSameOrAfter(this.toDate, "day")) {
        this.toDate = date;
      }
    },
    toDateSelected(date) {
      if (!this.fromDate || !moment(date).isSameOrAfter(this.fromDate, "day")) {
        this.fromDate = date;
      }
    },
    startDateSelected(date) {
      if (
        !this.eventInfo.endDate ||
        moment(date).isSameOrAfter(this.eventInfo.endDate, "day")
      ) {
        this.eventInfo.endDate = date;
      }
    },
    startVoteSelected(date) {
      if (
        !this.eventInfo.endVote ||
        moment(date).isSameOrAfter(this.eventInfo.endVote, "day")
      ) {
        this.eventInfo.endVote = date;
      }
    },
    endDateSelected(date) {
      if (
        !this.eventInfo.startDate ||
        !moment(date).isSameOrAfter(this.eventInfo.startDate, "day")
      ) {
        this.eventInfo.startDate = date;
      }
    },
    endVoteSelected(date) {
      if (
        !this.eventInfo.startVote ||
        !moment(date).isSameOrAfter(this.eventInfo.startVote, "day")
      ) {
        this.eventInfo.startVote = date;
      }
    },
    // startVoteSelected(date) {
    //   if (
    //     !this.eventInfo.endDate ||
    //     moment(date).isSameOrAfter(this.eventInfo.endDate, "day")
    //   ) {
    //     this.eventInfo.endDate = moment(date).add(1, 'days').format('YYYY-MM-DD');
    //     this.eventInfo.endVote = moment(date).add(1, 'days').format('YYYY-MM-DD');
    //   }
    // },
    // endDateSelected(date) {
    //   if (
    //     !this.eventInfo.startDate ||
    //     !moment(date).isSameOrAfter(this.eventInfo.startDate, "day")
    //   ) {
    //     this.eventInfo.startDate = date;
    //   }
    // },
    // endVoteSelected(date) {
    //   if (
    //     this.eventInfo.endDate &&
    //     moment(date).isAfter(this.eventInfo.endDate, "day")
    //   ) {
    //     this.eventInfo.endDate = date;
    //     this.eventInfo.startVote = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    //   } else if(moment(date).isSameOrBefore(this.eventInfo.startVote, "day")) {
    //     this.eventInfo.startVote = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    //   }
    // },
    validate() {
      this.$v.$touch();
      return this.isValidGame;
    },
    criteria(input) {
      return input.trim().toLowerCase();
    },
    availableOptions(list, input) {
      const criteria = this.criteria(input);
      // Filter out already selected options
      const options = list;

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.text.toLowerCase().indexOf(criteria) > -1
        );
      }

      // Show all options available
      return options;
    },
    ckStateGame(val) {
      let field = this.$v.eventInfo[val];
      return !field.$dirty || !field.$invalid;
    },
    ckStateExtentInfo(index, val) {
      let infos = this.$v.eventInfo.extensionInfos;
      return (
        !infos.$each[index][val].$dirty || !infos.$each[index][val].$invalid
      );
    },
    ckStateLinkInfos(index, val) {
      let infos = this.$v.eventInfo.linkInfos;
      return (
        !infos.$each[index][val].$dirty || !infos.$each[index][val].$invalid
      );
    },
    ckStateUrlInfos(index, val) {
      let infos = this.$v.eventInfo.urlInfos;
      return (
        !infos.$each[index][val].$dirty || !infos.$each[index][val].$invalid
      );
    },
    resetData() {
      this.page = 1;
      this.eventId = "";
      this.fromDate = "";
      this.toDate = "";
      this.perPage = 20;
    },
    deleteModal(item) {
      this.eventId = item.eventId;
      this.$root.$emit("bv::show::modal", "deleteEvent");
    },
    confirmGivePoint(item) {
      this.voteUser = item;
      this.$root.$emit("bv::show::modal", "confirmPoint");
    },
    reseteventInfo(data) {
      // console.log(JSON.stringify(this.eventTemp))
      // console.log(JSON.stringify(this.eventInfo))

      if (
        !isEqual(this.eventInfo, this.eventTemp) &&
        !this.isEnableCloseModal
      ) {
        this.showModalConfirmCloseEvent();
        data.preventDefault();
        return;
      }

      this.eventInfo.title_modal = "";
      this.eventInfo.file = null;
      this.eventInfo.title = "";
      this.eventInfo.description = "";
      this.eventInfo.extensionInfos = [];
      this.eventInfo.linkInfos = [];
      this.eventInfo.urlInfos = [];
      this.eventInfo.gamerIds = [];
      this.options_gamer = [];
      this.search_game = "";
      this.search_gamer = "";
      (this.selected_name = ""), (this.selected_tags = []), (this.gameType = 0);
      this.eventInfo.selected_sort = 1;
      this.eventInfo.startDate = null;
      this.eventInfo.endDate = null;
      this.eventInfo.startVote = null;
      this.eventInfo.endVote = null;
      this.selectedGamer = false;
      this.eventInfo.venue = "";
      this.eventInfo.prize = "";
      this.eventInfo.status = false;
      this.eventInfo.other = false;
      this.eventInfo.otherName = '';
      this.isWinnerSelected = false;
      this.isWinnerOtherSelected = false;
      this.filename = "";

      this.isEnableCloseModal = false;
      this.$v.$reset();
    },
    handleUpdateEvent() {
      this.eventTemp = { ...this.eventInfo };
      this.resetItemArraysEventTemp();
    },
    resetConfirmModal() {
      this.params = null;
      this.closeModalUpdate = null;
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    addAttribute() {
      if (this.eventInfo.extensionInfos.length > this.MAX_ATTRIBUTES) return;
      this.eventInfo.extensionInfos.push({ key: "", value: "" });
    },
    removeAttribute(index) {
      this.eventInfo.extensionInfos.splice(index, 1);
    },
    addAttributeLink() {
      if (this.eventInfo.linkInfos.length > this.MAX_ATTRIBUTES) return;
      this.eventInfo.linkInfos.push({ key: "", value: "" });
    },
    removeAttributeLink(index) {
      this.eventInfo.linkInfos.splice(index, 1);
    },
    addAttributeUrl() {
      if (this.eventInfo.urlInfos.length > this.MAX_ATTRIBUTES) return;
      this.eventInfo.urlInfos.push({ key: "", value: "" });
    },
    removeAttributeUrl(index) {
      this.eventInfo.urlInfos.splice(index, 1);
    },
    async handleDetail(row, type) {
      if (this.type_action === type && row.detailsShowing) {
        row.item.isLoading = false;
        this.isDetailRow = false;
        this.isVoteRow = false;
        this.type_action = type;
        row.item.type_action = type;
        row.item.isDetailRow = false;
        row.toggleDetails();
        return;
      }

      row.item.isLoading = true;
      row.item.isHistoryLoading = false;

      if (row.detailsShowing) row.toggleDetails();

      this.isDetailRow = true;
      this.isVoteRow = false;
      row.item.isDetailRow = true;

      let currentIdx = this.items.findIndex(
        (game) => game.eventId === row.item.eventId
      );

      if (currentIdx > -1) {
        this.items[currentIdx].content = row.item.id;
      }

      const result = await this.$store
        .dispatch("adminEvent/getDetailEvent", row.item.eventId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        let content = result;
        this.type_action = type;
        row.item.type_action = type;
        content.picture = `${process.env.VUE_APP_BACKEND + content.picture}`;
        content.extensionInfos = JSON.parse(content.extensionInfos);
        content.linkInfos = JSON.parse(content.linkInfos);
        content.urlInfos = JSON.parse(content.urlInfos);
        content.labelFileName = this.getNamePicture(result.picture);

        if (currentIdx > -1) {
          this.items[currentIdx].content = content;
        }

        row.toggleDetails();
        row.item.isLoading = false;
      }
    },
    async handleDetailHistoryEvent(row, type) {
      if (this.type_action === type && row.detailsShowing) {
        row.item.isLoading = false;
        this.isDetailRow = false;
        this.isVoteRow = false;
        this.type_action = type;
        row.item.isDetailRow = false;
        row.item.type_action = type;
        row.toggleDetails();
        return;
      }

      row.item.isLoading = false;
      row.item.isHistoryLoading = true;

      if (row.detailsShowing) row.toggleDetails();

      this.isDetailRow = false;
      row.item.isDetailRow = false;
      this.isVoteRow = true;

      let currentIdx = this.items.findIndex(
        (game) => game.eventId === row.item.eventId
      );

      if (currentIdx > -1) {
        this.items[currentIdx].content = row.item.id;
      }

      this.eventId = row.item.eventId;

      const result = await this.$store
        .dispatch("vote/voteHistoryEvent", row.item.eventId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        let content = {};
        this.type_action = type;
        row.item.type_action = type;

        content.table = result;
        content.labelFileName = this.getNamePicture(row.item.picture);

        content.table = content.table.map((event) => {
          event.votedDate = moment(event.votedDate).format("YYYY-MM-DD");
          event.totalTickets = this.formatPoint(event.totalTickets);
          event.twitterUsername = event.twitterUsername ? `@${event.twitterUsername}` : "";
          return event;
        });

        if (currentIdx > -1) {
          this.items[currentIdx].content = content;
        }
        row.toggleDetails();
        row.item.isHistoryLoading = false;
      }
    },
    handleSuccessResp(close) {
      setTimeout(() => {
        close();
        this.msgSuccess = null;
        this.dismissCountDown = 0;
      }, 2000);
    },
    onOptionGame({ option }) {
      this.search_game = "";
      this.selected_name = option.text;
      this.eventInfo.gameId = option.value;
    },
    onOptionWinner({ option }) {
      this.search_winner = "";
      this.selected_winner = option.text;
      this.eventInfo.winner = option.value;
      this.isWinnerSelected = option.value ? true : false;
    },
    loadDataInfo(item, result) {
      this.eventInfo.eventId = "";
      this.eventInfo.title = result.title;
      this.eventId = item.eventId;
      this.eventInfo.description = result.description;
      this.eventInfo.startDate = result.startDate
        ? moment(result.startDate).format("YYYY-MM-DD")
        : null;
      this.eventInfo.endDate = result.endDate
        ? moment(result.endDate).format("YYYY-MM-DD")
        : null;
      this.eventInfo.startVote = result.startVote
        ? moment(result.startVote).format("YYYY-MM-DD")
        : null;
      this.eventInfo.endVote = result.endVote
        ? moment(result.endVote).format("YYYY-MM-DD")
        : null;
      this.eventInfo.selected_sort = result.topPosition;
      this.eventInfo.gameId = result.gameId;
      let game = this.options_game.find(
        (option) => option.value === result.gameId
      );
      this.selected_name = game ? game.text : "";
      this.eventInfo.onlineFlag = result.onlineFlag;
      this.eventInfo.venue = result.venue;
      this.eventInfo.prize = result.prize;
      this.eventInfo.status = result.isVoteOther;

      if(result.winnerOther) {
        this.eventInfo.other = true;
        this.eventInfo.otherName = result.winnerOther;
      }

      this.gameType = result.gamerType;
      this.selectedGamer = this.gameType ? true : false;

      if (this.gameType === 0) {
        this.options_gamer = this.options_player;
      } else {
        this.options_gamer = this.options_team;
      }

      this.eventInfo.finished = result.finished;
      this.eventInfo.winner = result.winner;
      //this.isWinnerSelected = result.winner ? true : false;
      let winnerChoosen = this.options_gamer.find(
        (option) => option.value === result.winner
      );

      this.selected_winner = winnerChoosen ? winnerChoosen.text : "";

      this.options_winner = result.gamers.map((gamer) => {
        return {
          value: gamer.gamerId,
          text: gamer.name,
        };
      });

      this.options_winner.unshift({
        value: null,
        text: '-'
      });

      this.filename = this.getNamePicture(result.picture);

      this.eventInfo.gamerIds = result.gamers?.map((item) => item.gamerId);
      this.renderTags(this.eventInfo.gamerIds);

      let extendsInfo = result.extensionInfos
        ? JSON.parse(result.extensionInfos)
        : [{ key: "", value: "" }];

      if (!Array.isArray(extendsInfo)) {
        this.eventInfo.extensionInfos = [];

        Object.entries(extendsInfo).forEach((item) => {
          this.eventInfo.extensionInfos.push({
            key: item[0],
            value: item[1],
          });
        });
      }

      let linkInfos = result.linkInfos
        ? JSON.parse(result.linkInfos)
        : [{ key: "", value: "" }];

      if (!Array.isArray(linkInfos)) {
        this.eventInfo.linkInfos = [];

        Object.entries(linkInfos).forEach((item) => {
          this.eventInfo.linkInfos.push({
            key: item[0],
            value: item[1],
          });
        });
      }

      let urlInfos = result.urlInfos
        ? JSON.parse(result.urlInfos)
        : [{ key: "", value: "" }];

      if (!Array.isArray(urlInfos)) {
        this.eventInfo.urlInfos = [];

        Object.entries(urlInfos).forEach((item) => {
          this.eventInfo.urlInfos.push({
            key: item[0],
            value: item[1],
          });
        });
      }

      this.eventTemp = { ...this.eventInfo };
      this.resetItemArraysEventTemp();
    },
    resetTags() {
      this.selected_tags = [];
    },
    resetItemArraysEventTemp() {
      if (this.eventTemp.extensionInfos) delete this.eventTemp.extensionInfos;
      if (this.eventTemp.linkInfos) delete this.eventTemp.linkInfos;
      if (this.eventTemp.urlInfos) delete this.eventTemp.urlInfos;
      if (this.eventTemp.gamerIds) delete this.eventTemp.gamerIds;

      this.eventTemp.gamerIds = cloneDeep(this.eventInfo.gamerIds);
      this.eventTemp.linkInfos = cloneDeep(this.eventInfo.linkInfos);
      this.eventTemp.urlInfos = cloneDeep(this.eventInfo.urlInfos);
      this.eventTemp.extensionInfos = cloneDeep(this.eventInfo.extensionInfos);
    },
    handleCheckOther($event) {
      this.isWinnerOtherSelected = $event;
    }
  },
};
</script>